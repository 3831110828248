import React, { Fragment } from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { AnimatedBackground, ContentContainer } from "@uaveiro/ui";

const Mask = props => {
  return (
    <ContentContainer
      backgroundColor={props.theme.loadings.bgColor}
      position="absolute"
      {...props}
    />
  );
};

const LoadingCard = ({ ...theme }) => {
  return (
    <Fragment>
      <ContentContainer height="40px" width="100%">
        <AnimatedBackground height="40px">
          <Mask height="20px" right="0" width="70%" {...theme} />
          <Mask height="15px" top="20px" width="100%" {...theme} />
          <Mask height="5px" top="35px" right="0" width="90%" {...theme} />
        </AnimatedBackground>
      </ContentContainer>

      <ContentContainer
        position="relative"
        top="50px"
        height="90px"
        width="100%"
      >
        <AnimatedBackground height="90px">
          <Mask height="15px" top="0" right="0" width="40%" {...theme} />
          <Mask height="10px" top="15px" width="100%" {...theme} />
          <Mask height="15px" top="25px" right="0" width="40%" {...theme} />
          <Mask height="10px" top="40px" width="100%" {...theme} />
          <Mask height="15px" top="50px" right="0" width="50%" {...theme} />
          <Mask height="10px" top="65px" width="100%" {...theme} />
          <Mask height="15px" top="75px" right="0" width="60%" {...theme} />
        </AnimatedBackground>
      </ContentContainer>
    </Fragment>
  );
};

const Loading = ({ className, theme }) => {
  return (
    <div className={className}>
      <div className="row no-gutters">
        <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0">
          <LoadingCard theme={theme} />
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string
};

const LoadingWithStyles = styled(Loading)`
  min-height: 400px;
`;

export default withTheme(LoadingWithStyles);
