import React, { Fragment } from "react";
import PropTypes from "prop-types";
//Styles
import styled, { withTheme } from "styled-components";
import { darken, rem } from "polished";
//components
import { InstituteSearch } from "../../molecules";
import { Link } from "../../atoms";
import JournalNavigation from "../../../templates/Journal/JournalNavigation";
import { SideNav, SmartContainer } from "../../organisms";

import {
  Icon,
  Image,
  Text,
  Title,
  ScreenReaderText,
  VerticalList,
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
  AccordionTitle,
  HorizontalList,
  ListItem,
  Dropdown,
  DropdownList
} from "@uaveiro/ui";
import { ContentContainer } from "../../molecules";
//Context
import { StoreContext } from "../../../context/StoreContext";
//Locales
import { FormattedMessage } from "react-intl";
import { SubWebNavigation } from "../../../templates";

function MobileNavbar({
  className,
  isSearchOpen,
  searchToggle,
  institute,
  onHandleAnimation,
  onChangeLang,
  clearHashRoute,
  onHandleSearch,
  onChangeZoom,
  onChangeAccessibilityFilter,
  hasHash,
  isFirefox,
  isFocus,
  menuClass,
  accessibilityValue,
  lang,
  zoomValue,
  isOpen,
  journalMobileNavigationHeight,
  history,
  journalNavigationShadow,
  theme,
  hasEmbeddedSearch,
  hasInstituteLogo,
  isDropdownOpen,
  setDropdownOpen,
  subWebNavigationShadow,
  hideSubWebLogoDescription,
  setFavorite,
  removeFavorite,
  ...props
}) {
  return (
    <nav className={className}>
      <ScreenReaderText>
        <Title text="Navegação Principal" />
      </ScreenReaderText>
      <div className="mobile-nav-bar">
        <div className="container">
          <div className="row no-gutters align-items-center justify-content-between container-row">
            <div className="col-auto">
              {hasInstituteLogo ? (
                <div className="institute-logo">
                  <Link
                    href="/"
                    display="inline-block"
                    aria-label="Logótipo da Universidade de Aveiro, ir para página inicial"
                  >
                    {isSearchOpen ? (
                      <Fragment>
                        <Image
                          src={theme.components.mobileNavBar.mobileBlackLogo}
                          className="ua-logo"
                          objectFit="unset"
                          alt="Página inicial da Universidade de Aveiro"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Image
                          src={theme.components.mobileNavBar.mobileWhiteLogo}
                          className="ua-logo"
                          objectFit="unset"
                          alt="Página inicial da Universidade de Aveiro"
                        />
                      </Fragment>
                    )}
                  </Link>
                </div>
              ) : (
                <ContentContainer marginRight="30px">
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    href="/"
                    fontSize={rem("16px")}
                    display="inline-block"
                    color={theme.components.webNavBar.linksColor}
                    fontDecoration="none"
                  >
                    Universidade de Aveiro
                  </Link>
                </ContentContainer>
              )}
            </div>

            <div className="col align-content-end icons-container">
              <HorizontalList display="inline-block">
                <ListItem className="accessibility" marginRight="12px">
                  <Dropdown
                    minContentWidth="60px"
                    right="-5px"
                    onMouseEnter={() => setDropdownOpen(true)}
                    onClick={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                    onFocus={() => setDropdownOpen(true)}
                  >
                    <div className="acessibility-icon-dropdown">
                      <Link
                        fontDecoration="none"
                        fontWeight="500"
                        color="#fff"
                        fontSize="18px"
                        lighten
                        aria-label="acessibilidade"
                        onClick={e => e.preventDefault()}
                        background={
                          accessibilityValue === 1
                            ? theme.components.webNavBar.accessibilityOnColor
                            : "inherit"
                        }
                      >
                        <Icon
                          minWidth="18px"
                          {...institute.accessibility}
                          color={
                            accessibilityValue === 1
                              ? "#000"
                              : theme.components.mobileNavBar.iconColor
                          }
                        />
                      </Link>
                    </div>
                    {isDropdownOpen && (
                      <DropdownList fontWeight="400">
                        <Link
                          className={`acessibility-icon  ${
                            accessibilityValue === 1 ? "active" : ""
                          }`}
                          onClick={onChangeAccessibilityFilter}
                          aria-label="Mudar o contraste"
                          fontSize="18px"
                        >
                          <Icon
                            name="fas fa-adjust"
                            color={
                              theme.components.webNavBar
                                .accessibilityMenuIconColor
                            }
                          />
                        </Link>

                        {!isFirefox && (
                          <Link
                            aria-label="Fazer zoom na página"
                            className="acessibility-icon"
                            onClick={onChangeZoom}
                            fontSize="18px"
                          >
                            <Icon
                              name={`fal fa-search-${
                                zoomValue === "150%" ? "minus" : "plus"
                              }`}
                              color={
                                theme.components.webNavBar
                                  .accessibilityMenuIconColor
                              }
                            />
                          </Link>
                        )}
                      </DropdownList>
                    )}
                  </Dropdown>
                </ListItem>

                <ListItem marginRight="15px">
                  <Link
                    aria-label={institute.language.alt}
                    fontSize="18px"
                    fontWeight="300"
                    color={theme.components.mobileNavBar.textColor}
                    onClick={onChangeLang}
                    fontDecoration="none"
                  >
                    {lang === "pt" ? "EN" : "PT"}
                  </Link>
                </ListItem>

                <ListItem marginRight="15px">
                  {hasEmbeddedSearch ? (
                    <Link
                      aria-label="pesquisar"
                      fontSize="18px"
                      onClick={e => searchToggle(e)}
                    >
                      <Icon
                        name="fal fa-search"
                        cursor="pointer"
                        color={theme.components.mobileNavBar.iconColor}
                        minWidth="18px"
                        darken
                      />
                    </Link>
                  ) : (
                    <Link
                      aria-label="pesquisar"
                      fontSize="18px"
                      //onClick={}
                    >
                      <Icon
                        name="fal fa-search"
                        cursor="pointer"
                        color={theme.components.mobileNavBar.iconColor}
                        minWidth="18px"
                        darken
                      />
                    </Link>
                  )}
                </ListItem>

                <ListItem>
                  <button
                    aria-label={isOpen ? "Fechar menu" : "Abrir menu"}
                    onClick={onHandleAnimation}
                  >
                    <Icon
                      name={`${isOpen ? "fal fa-times" : "fal fa-bars"}`}
                      cursor="pointer"
                      color={theme.components.mobileNavBar.iconColor}
                      minWidth="18px"
                      size="small"
                      darken
                    />
                  </button>
                </ListItem>
              </HorizontalList>
            </div>

            {isSearchOpen ? (
              <div className="col">
                {/*Falta Autofocus*/}
                <InstituteSearch
                  isFocus={isFocus}
                  removeLeftIcon
                  onKeyPress={onHandleSearch}
                  showClear
                  clear={e => searchToggle(e)}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className={`institute-mobile-links ${menuClass}`}>
          {isOpen && (
            <div className="container">
              <Accordion>
                {institute.links.map((item, index) => {
                  return (
                    <AccordionItem key={index}>
                      <AccordionItemTitle
                        onClick={() =>
                          history.push(clearHashRoute(item.baseUrl))
                        }
                        tabIndex={-1}
                      >
                        <AccordionTitle
                          fontWeight="300"
                          fontSize="16px"
                          hasLinks={item.hasMobileLinks}
                          baseLinkUrl={`/${lang}/${item.baseUrl}`}
                          color={theme.components.mobileNavBar.linksColor}
                        >
                          <FormattedMessage
                            id={item.resource}
                            defaultMessage={item.title}
                          />
                        </AccordionTitle>
                      </AccordionItemTitle>
                      {item.hasMobileLinks && (
                        <AccordionItemBody>
                          <VerticalList>
                            {item.list.map((item, index) => {
                              return (
                                <Link
                                  key={index}
                                  href={`/${lang}/${item.url}`}
                                  fontDecoration="none"
                                  aria-label={item.title}
                                >
                                  <Text
                                    paddingTop="10px"
                                    paddingBottom="10px"
                                    color={
                                      theme.components.mobileNavBar.linksColor
                                    }
                                    {...institute.mobileStyles.link}
                                  >
                                    <FormattedMessage
                                      id={item.resource}
                                      defaultMessage={item.title}
                                    />
                                  </Text>
                                </Link>
                              );
                            })}
                          </VerticalList>
                        </AccordionItemBody>
                      )}
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          )}
        </div>
      </div>

      <StoreContext.Consumer>
        {value =>
          value.state.showSideNav && (
            <SmartContainer>
              <SideNav {...value.state.sideNavData} />
            </SmartContainer>
          )
        }
      </StoreContext.Consumer>

      {props.GlobalStore.state.isNews && (
        <JournalNavigation
          title="Notícias"
          resource="app.journal.nav.title"
          journalMobileNavigationHeight={journalMobileNavigationHeight + "px"}
          journalNavigationShadow={journalNavigationShadow}
        />
      )}

      {props.GlobalStore.state.isSubWeb && (
        <SubWebNavigation
          hideSubWebLogoDescription={hideSubWebLogoDescription}
          subWebNavigationShadow={subWebNavigationShadow}
        />
      )}
    </nav>
  );
}

MobileNavbar.defaultProps = {
  hasInstituteLogo: true,
  hasEmbeddedSearch: true,
  setFavorite: () => null,
  removeFavorite: () => null
};

MobileNavbar.propTypes = {
  className: PropTypes.string,
  openState: PropTypes.bool,
  onClick: PropTypes.func,
  isSearchOpen: PropTypes.bool.isRequired,
  searchToggle: PropTypes.func.isRequired,
  institute: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onHandleAnimation: PropTypes.func.isRequired,
  onChangeLang: PropTypes.func.isRequired,
  clearHashRoute: PropTypes.func.isRequired,
  onHandleSearch: PropTypes.func.isRequired,
  onChangeZoom: PropTypes.func.isRequired,
  onChangeAccessibilityFilter: PropTypes.func.isRequired,
  hasHash: PropTypes.string.isRequired,
  isFirefox: PropTypes.bool.isRequired,
  isFocus: PropTypes.bool.isRequired,
  menuClass: PropTypes.string.isRequired,
  accessibilityValue: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
  zoomValue: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  journalMobileNavigationHeight: PropTypes.number,
  journalNavigationShadow: PropTypes.bool,
  GlobalStore: PropTypes.object,
  setFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired
};

const MobileNavBarWithStyles = styled(MobileNavbar)`
  z-index: 100;
  height: auto;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  button {
    background-color: transparent;
    border: none;
    &:hover {
      i {
        color: ${darken(0.5, "#fff")};
      }
    }
  }

  .mobile-nav-bar {
    //highContrast only border
    border-top: ${props => props.theme.highContrast.border};
    border-bottom: ${props => props.theme.highContrast.border};

    background-color: ${props =>
      props.theme.components.mobileNavBar.backgroundColor};

    ${props =>
      props.isSearchOpen
        ? `
      background: ${props.theme.components.mobileNavBar.isSearchOpenBackgroundColor};
      .icons-container {
        display: none;
      }`
        : null}
  }

  .container-row {
    transition: height 0.2s ease;
    -webkit-transition: height 0.2s ease;
    height: ${props => props.height - 20}px;
  }

  .icons-container {
    text-align: right;
  }

  .institute-logo {
    img {
      height: 44px;
    }
  }

  .institute-mobile-actions {
    display: inline;
    text-align: right;
    i {
      font-size: 22px;
    }
  }

  /*Dropdown*/
  .institute-mobile-links {
    background-color: ${props =>
      props.theme.components.mobileNavBar.dropDownBackgroundColor};
    width: 100%;
    overflow: hidden;
    .container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .state-close {
    display: none;
  }

  .open {
    display: block;
    -webkit-animation: open-mobile-nav-bar 0.3s ease-in;
    -moz-animation: open-mobile-nav-bar 0.3s ease-in;
    animation: open-mobile-nav-bar 0.3s ease-in;
    .accordion__item {
      -webkit-animation: fade-in-mnb-items 0.6s ease-in;
      -moz-animation: fade-in-mnb-items 0.6s ease-in;
      animation: fade-in-mnb-items 0.6s ease-in;
    }
  }

  .close {
    -webkit-animation: close-mobile-nav-bar 0.4s ease-out forwards;
    -moz-animation: close-mobile-nav-bar 0.4s ease-out forwards;
    animation: close-mobile-nav-bar 0.4s ease-out forwards;
    .accordion__item {
      -webkit-animation: fade-out-mnb-items 0.2s ease-out forwards;
      -moz-animation: fade-out-mnb-items 0.2s ease-out forwards;
      animation: fade-out-mnb-items 0.2s ease-out forwards;
    }
  }

  //Acessibility icon dropdown
  .accessibility {
    position: relative;

    :first-child {
      margin-right: 0;
    }
    .acessibility-icon-dropdown {
      display: inline-block;
      padding: ${props => props.accessibilityDropdownPadding || "15px"};
    }

    .dropdown-content {
      .acessibility-icon {
        padding: 8px 0;
        text-align: center;

        &:hover {
          background-color: ${props =>
            props.theme.components.webNavBar.dropDownHoverBackgroundColor};
          cursor: pointer;
          i {
            color: ${props =>
              props.theme.components.webNavBar.accessibilityIconHoverColor};
          }
        }
      }
      .acessibility-icon.active {
        background-color: ${props =>
          props.theme.components.webNavBar.accessibilityActiveBgColor};
        i {
          color: ${props =>
            props.theme.components.webNavBar.accessibilityIconActiveColor};
        }
      }
    }
  }

  /*Open and Fade in items*/
  @keyframes open-mobile-nav-bar {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 400px;
    }
  }

  @keyframes fade-in-mnb-items {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /*Close and Fade in items*/
  @keyframes close-mobile-nav-bar {
    0% {
      max-height: 400px;
    }
    100% {
      max-height: 0;
    }
  }

  @keyframes fade-out-mnb-items {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default withTheme(MobileNavBarWithStyles);
