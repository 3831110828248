export default [
  {
    name: "Breadcrumbs",
    data: {
      pt: [
        { text: "UA", url: "/" },
        { text: "investigar", url: "/investigacao" },
        { text: "atividade científica", url: "/atividade-cientifica" },
        { text: "projetos i&d", url: "/projetos-id" }
      ],
      en: [
        { text: "UA", url: "/" },
        { text: "research", url: "/uaresearch" },
        { text: "scientific activity", url: "/scientific-activity" },
        { text: "r&d projetos", url: "/id-projects" }
      ]
    }
  },
  {
    name: "ProjectsList",
    data: {
      pt: {
        title: "Projetos I&D",
        content:
          "\nA Universidade é um parceiro privilegiado de empresas e outras organizações nacionais e internacionais com as quais coopera em numerosos projetos e para os quais presta serviços importantes.\n\nDurante 2024, 417 projetos nacionais e internacionais de investigação e desenvolvimento e transferência de tecnologia estiveram ativos na UA, dos quais 56 financiados pelo programa ERASMUS+ (KA2), 23 pelo programa Horizonte 2020, 62 pelo Horizonte Europa e 9 pelo INTERREG. A UA é ​​instituição de acolhimento de 12 bolsas ERC e 1 ERAChair, coordenadora de 4 projetos TWINNING e de 3 projetos europeus Horizonte Europa que contribuem para as Missões da Comissão Europeia, entre outros projetos europeus estratégicos.\n\nConsulte os projetos de investigação financiados por fundos nacionais, europeus e outros, com a coordenação ou participação de investigadores da Universidade de Aveiro.\n\nEsta lista encontra-se em atualização permanente.\n\n"
      },
      en: {
        title: "R&D projects",
        content:
          "\nThe Universidade de Aveiro is a privileged partner of companies and other national and international organizations with whom it cooperates in numerous projects and for which it provides important services.\n\nDuring 2024, 417 national and international research and technology transfer projects have been active in UAveiro, of which 56 funded by ERASMUS+ (KA2), 23 by Horizon 2020, 62 by Horizon Europe and 9 by INTERREG.\n\nThe UAveiro is the host institution of 12 ERC Grants and 1 ERA Chair, coordinator of 4 TWINNING projects and 3 Horizon Europe projects contributing to the EU Missions, among other relevant and strategic European projects.\n\nSee projects funded by national, European and other funds with the coordination or participation of researchers from the University of Aveiro.\n\nThe project list is being continuously updated. "
      }
    }
  }
];
