import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { SubWebWarnings } from "@uaveiro/ui";
//Context
import { StoreContext } from "../../context/StoreContext";

const WarningsTemplate = ({ match, data, tabs, size, ...props }) => {
  const context = useContext(StoreContext);
  const [state, setState] = useState({
    isLoading: true,
    hasError: false,
    data: [],
    activeTab: 1,
    page: 4,
    totalPages: 0
  });
  const {
    params: { lang }
  } = match;

  const onFilterData = (activeTab = 1, page = 4) => {
    if (activeTab !== state.activeTab) {
      setState(prevState => {
        return {
          ...prevState,
          page: 4,
          totalPages: 0
        };
      });
    }
    const [content] = data.filter(item => item.id === activeTab);

    if (content !== undefined) {
      const list = content.list.filter((item, i) => i < page && item);

      setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          data: list,
          activeTab: content.id,
          page,
          totalPages: content.list.length 
        };
      });
    }
  };

  useEffect(() => {
    onFilterData(state.activeTab);
  }, []);

  const onChangeTab = id => {
    onFilterData(id);
  };

  const onHandleMore = () => {
    onFilterData(state.activeTab, state.page + 3);
  };

  const departmentProps = {
    lang,
    tabs,
    onChangeTab,
    action: {
      text: lang === 'pt' ? 'ver mais' : 'see more',
      show: state.page < state.totalPages,
      onClick: onHandleMore
    },
    activeColor: context.state.activeColor,
    isMobile: size ? size.width <= 991 : false,
    ...state,
    ...props
  };

  return <SubWebWarnings {...departmentProps} />;
};

WarningsTemplate.propTypes = {
  match: PropTypes.object
};

export default withRouter(WarningsTemplate);
