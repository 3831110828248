import React, {
  useMemo,
  useContext,
  useState,
  useEffect,
  Fragment
} from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
//Components
import { Link } from "../../atoms";
import { AccessibilityLogo } from "../../molecules";
import { JournalFooter } from "../../organisms";

import {
  Icon,
  Text,
  Image,
  ScreenReaderText,
  Section,
  ContentContainer,
  HorizontalList,
  VerticalList,
  ListItem,
  LastUpdate
} from "@uaveiro/ui";

//Utils
import { lang } from "../../../translations";
//Locales
import { FormattedMessage } from "react-intl";
import { compose } from "../../../services/utilities";
import { withSize } from "react-sizeme";
import { StoreContext } from "../../../context/StoreContext";
import moment from "moment";

const Footer = ({ className, social, size, theme }) => {
  const { state } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);

  let location = useLocation();
  if (
    location.pathname == "pt" ||
    location.pathname == "en" ||
    location.pathname == ""
  )
    location.pathname = "/";
  const pageDate = moment(state.pageLastModified);
  const isPageDateValid = pageDate.isValid();

  const classModifier = (className = "row") =>
    size.width <= 1200 ? { className } : {};

  const iconNames = useMemo(() => {
    return {
      0: theme.icons.facebook,
      1: theme.icons.instagram,
      2: theme.icons.twitter,
      3: theme.icons.linkedIn,
      4: theme.icons.youtube,
      5: theme.icons.spotify
    };
  }, []);

  useEffect(() => {
    //debugger;
    setLoading(true);

    let time = setTimeout(() => {
      setLoading(false);
    }, 700);

    return () => clearTimeout(time);
  }, [location.pathname]);

  //Is a external page
  if (state.isExternal || loading) {
    return null;
  }

  return (
    <footer className={className}>
      <ScreenReaderText>
        <Text as="h2" text="Rodapé" />
      </ScreenReaderText>

      {state.isNews && <JournalFooter lang={lang()} />}

      {state.pageLastModified && !state.isNews && isPageDateValid && (
        <LastUpdate date={pageDate.format("DD MMMM YYYY")} />
      )}

      <Section
        backgroundColor={theme.components.footer.backgroundColor}
        lgPaddingBottom="0"
        paddingTop="40px"
        paddingBottom="40px"
      >
        <div className="container">
          <div className="row no-gutters justify-content-between">
            <div className="col-xl-3 col-lg-12">
              <ContentContainer
                lgMarginBottom="25px"
                mobileLgPaddingBottom="25px"
                mobileLgBorderBottom={`1px solid ${theme.components.footer.borderColor}`}
              >
                <ContentContainer marginBottom="20px" mobileXsDisplay="none">
                  <Text
                    as="h3"
                    size="small"
                    fontWeight="500"
                    textTransform="uppercase"
                    color={theme.components.footer.color}
                  >
                    <FormattedMessage
                      id="app.footer.follow"
                      defaultMessage="segue-nos"
                    />
                  </Text>
                </ContentContainer>
                <div className="row footer-first-row">
                  <HorizontalList
                    display="inline-block"
                    className="col-xl-12 col-6 col-sm-8 col-md-9 col-lg-10"
                  >
                    {social.links.map((item, index) => {
                      return (
                        <ListItem marginRight="15px" key={index}>
                          <Link
                            href={item.url}
                            aria-label={item.ariaLabel}
                            target="_blank"
                            lighten
                            native
                          >
                            <Icon
                              name={iconNames[index]}
                              color={
                                theme.components.footer.socialMediaIconsColor
                              }
                              fontSize="24px"
                            />
                          </Link>
                        </ListItem>
                      );
                    })}
                  </HorizontalList>
                  <div className="footer-sgs col-xl-12 col-6 col-sm-4 col-md-3 col-lg-2">
                    <div className="sgs-logo">
                      <Link
                        fontDecoration="none"
                        href={`${`/${lang()}/covid-19-info`}`}
                        native
                      >
                        <Image
                          height="100px"
                          src="/imgs/selo_sgs.png"
                          alt="Logótipo do Consórcio Europeu de Universidades Inovadoras"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </ContentContainer>
            </div>
            <div className="col-xl-2 col-lg-auto col-md-auto col-sm-auto col-6 pr-1">
              <Text
                as="h3"
                size="small"
                fontWeight="500"
                textTransform="uppercase"
                color={theme.components.footer.color}
                marginBottom="20px"
              >
                <FormattedMessage
                  id="app.footer.questions"
                  defaultMessage="Questões"
                />
              </Text>
              <VerticalList>
                <ListItem marginBottom="5px">
                  <Link
                    href={`${
                      lang() === "pt"
                        ? `https://forms.ua.pt/index.php?r=survey/index&sid=988175&lang=pt`
                        : `https://forms.ua.pt/index.php?r=survey/index&sid=988175&lang=en`
                    }`}
                    target="_blank"
                    aria-label="pergunta ao reitor"
                    fontSize="14px"
                    color={theme.components.footer.linkColor}
                    fontWeight="300"
                    native="false"
                  >
                    <FormattedMessage
                      id="app.nav.footer.askRector"
                      defaultMessage="pergunta ao reitor"
                    />
                  </Link>
                </ListItem>
                <ListItem marginBottom="5px">
                  <Link
                    href={`${
                      lang() === "pt"
                        ? `https://forms.ua.pt/index.php?r=survey/index&sid=115724&lang=pt`
                        : `https://forms.ua.pt/index.php?r=survey/index&sid=115724&lang=en`
                    }`}
                    target="_blank"
                    aria-label="pergunta ao provedor do estudante"
                    fontSize="14px"
                    color={theme.components.footer.linkColor}
                    fontWeight="300"
                    native="false"
                  >
                    <FormattedMessage
                      id="app.nav.footer.askOmbudsperson"
                      defaultMessage="pergunta ao provedor do estudante"
                    />
                  </Link>
                </ListItem>
                <ListItem marginBottom="5px">
                  <Link
                    href={`${
                      lang() === "pt"
                        ? `/${lang()}/reitoria/denuncias`
                        : `/${lang()}/reitoria/complaints`
                    }`}
                    aria-label="canal de denúncias"
                    fontSize="14px"
                    color={theme.components.footer.linkColor}
                    fontWeight="300"
                    native="true"
                  >
                    <FormattedMessage
                      id="app.nav.footer.askComplains"
                      defaultMessage="canal de denúncias"
                    />
                  </Link>
                </ListItem>
              </VerticalList>
            </div>
            <div className="col-xl col-lg-auto col-md-auto col-sm-auto col-6 pr-1">
              <ContentContainer>
                <Text
                  as="h3"
                  size="small"
                  fontWeight="500"
                  textTransform="uppercase"
                  color={theme.components.footer.color}
                  marginBottom="20px"
                >
                  <FormattedMessage
                    id="app.footer.links"
                    defaultMessage="Links Rápidos"
                  />
                </Text>
                <VerticalList>
                  <ListItem marginBottom="5px">
                    <Link
                      aria-label="sobre a ua"
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/universidade`
                          : `/${lang()}/university`
                      }`}
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.nav.about"
                        defaultMessage="sobre a UA"
                      />
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/tipo-curso`
                          : `/${lang()}/course-types`
                      }`}
                      aria-label="Cursos"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.nav.education.item.courses"
                        defaultMessage="cursos"
                      />
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/candidaturas`
                          : `/${lang()}/applications`
                      }`}
                      aria-label="candidaturas"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.nav.education.item.aplication"
                        defaultMessage="candidaturas"
                      />
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/departamentos-escolas`
                          : `/${lang()}/departments-schools`
                      }`}
                      aria-label="Departamentos/ Escolas"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.nav.education.item.departments"
                        defaultMessage="departamentos e escolas"
                      />
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/administracao-servicos`
                          : `/${lang()}/administracao-servicos`
                      }`}
                      aria-label="Serviços"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.footer.services"
                        defaultMessage="serviços"
                      />
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/unidades-investigacao`
                          : `/${lang()}/research-units`
                      }`}
                      aria-label="unidades de investigação"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.footer.researchUnits"
                        defaultMessage="unidades de investigação"
                      />
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href="https://www.ua.pt/pt/sbidm"
                      target="_blank"
                      aria-label="Biblioteca"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                      native
                    >
                      <FormattedMessage
                        id="app.footer.library"
                        defaultMessage="biblioteca"
                      />
                    </Link>
                  </ListItem>
                </VerticalList>
              </ContentContainer>
            </div>
            <div className="col-xl col-lg-auto col-md-auto col-sm-auto col-6 pr-1">
              <ContentContainer
                mobileXsBorderTop={`1px solid ${theme.components.footer.borderColor}`}
                mobileXsMarginTop="25px"
                mobileXsPaddingTop="25px"
              >
                {/* todo tradução */}
                <Text
                  as="h3"
                  size="small"
                  fontWeight="500"
                  textTransform="uppercase"
                  color={theme.components.footer.color}
                  marginBottom="20px"
                >
                  <FormattedMessage
                    id="app.footer.publics"
                    defaultMessage="públicos"
                  />
                </Text>
                <VerticalList>
                  {/* todo traduções */}
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/publico-futuros-estudantes`
                          : `/${lang()}/public-prospective-students`
                      }`}
                      aria-label="futuros estudantes"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.footer.prospectiveStudents"
                        defaultMessage="futuros estudantes"
                      />
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/publico-estudantes`
                          : `/${lang()}/public-students`
                      }`}
                      aria-label="estudantes da ua"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.footer.uaStudents"
                        defaultMessage="estudantes UA"
                      />
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/publico-estudantes-internacionais`
                          : `/${lang()}/public-internacional-student`
                      }`}
                      aria-label="estudantes internacionais"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.footer.internationalStudents"
                        defaultMessage="estudantes internacionais"
                      />
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/publico-alumni`
                          : `/${lang()}/public-alumni`
                      }`}
                      aria-label="alumni"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      alumni
                    </Link>
                  </ListItem>
                  <ListItem marginBottom="5px">
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/publico-pessoas`
                          : `/${lang()}/public-people`
                      }`}
                      aria-label="pessoas ua"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.footer.uaPeople"
                        defaultMessage="pessoas UA"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/publico-sociedade`
                          : `/${lang()}/public-society`
                      }`}
                      aria-label="sociedade"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.footer.society"
                        defaultMessage="sociedade"
                      />
                    </Link>
                  </ListItem>
                </VerticalList>
              </ContentContainer>
            </div>
            <div className="col-xl col-lg-12 col-md-12 col-sm-12 col-6 pr-1">
              <ContentContainer
                mobileLgBorderTop={`1px solid ${theme.components.footer.borderColor}`}
                mobileLgBorderBottom={`1px solid ${theme.components.footer.borderColor}`}
                lgMargintop="25px"
                mobileLgPaddingBottom="25px"
                mobileLgPaddingTop="25px"
                mobileXsBorderBottom="none"
                mobileXsPaddingBottom="0"
              >
                <Text
                  as="h3"
                  size="small"
                  fontWeight="500"
                  textTransform="uppercase"
                  color={theme.components.footer.color}
                  marginBottom="20px"
                >
                  <FormattedMessage
                    id="app.footer.contacts"
                    defaultMessage="Contactos"
                  />
                </Text>
                <div
                  {...classModifier(
                    "row justify-content-md-start justify-content-sm-between"
                  )}
                >
                  <div
                    {...classModifier(
                      "col-lg-auto col-md-auto col-sm-auto col-12"
                    )}
                  >
                    <Text
                      size="small"
                      fontWeight="300"
                      color={theme.components.footer.color}
                      marginBottom="20px"
                      lineHeight="1.4"
                    >
                      Campus Universitário de Santiago <br /> 3810-193 Aveiro{" "}
                      <br /> Portugal
                    </Text>
                  </div>
                  <div
                    {...classModifier(
                      "col-lg-auto col-md-auto col-sm-auto col-12"
                    )}
                  >
                    <Link
                      fontSize="14px"
                      fontWeight="300"
                      color={theme.components.footer.linkColor}
                      marginBottom="20px"
                      href="tel:+351234370200"
                      display="block"
                      native
                    >
                      +351 234 370 200
                    </Link>
                  </div>
                  <div
                    {...classModifier(
                      "col-lg-auto col-md-auto col-sm-auto col-12"
                    )}
                  >
                    <Link
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/contactos-gerais`
                          : `/${lang()}/contacts`
                      }`}
                      aria-label="contactos gerais"
                      fontSize="14px"
                      color={theme.components.footer.linkColor}
                      fontWeight="300"
                    >
                      <FormattedMessage
                        id="app.footer.general.contacts"
                        defaultMessage="contactos gerais"
                      />
                    </Link>
                  </div>
                </div>
              </ContentContainer>
            </div>
          </div>
        </div>
      </Section>
      <Section
        backgroundColor={theme.components.footer.backgroundColor}
        paddingTop="20px"
        paddingBottom="20px"
        borderTop={`1px solid ${theme.components.footer.borderColor}`}
        lgPaddingBottom="40px"
        smPaddingBottom="0"
        lgBorderTop="none"
        lgPaddingTop="0"
        mdPaddingTop="0"
        hasHighContrastBorderTop={false}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl col-lg-12">
              <ContentContainer
                lgMargintop="25px"
                lgMarginBottom="25px"
                mdMarginBottom="15px"
                mobileLgPaddingBottom="25px"
                mobileLgBorderBottom={`1px solid ${theme.components.footer.borderColor}`}
                mobileXsBorderTop={`1px solid ${theme.components.footer.borderColor}`}
                mobileXsMarginTop="25px"
                mobileXsPaddingTop="25px"
              >
                <div className="row">
                  <div className="col-auto">
                    <Link
                      fontSize="11px"
                      fontWeight="300"
                      color={theme.components.footer.linkColor}
                      marginBottom="10px"
                      display="block"
                      aria-label="Proteção de dados"
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/protecao-dados`
                          : `/${lang()}/gdpr`
                      }`}
                    >
                      <FormattedMessage
                        id="app.footer.protection"
                        defaultMessage="Proteção de dados"
                      />
                    </Link>
                  </div>
                  <div className="col-auto">
                    <Link
                      fontSize="11px"
                      fontWeight="300"
                      color={theme.components.footer.linkColor}
                      marginBottom="10px"
                      display="block"
                      aria-label="Termos de utilização"
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/termos-de-utilizacao`
                          : `/${lang()}/terms-of-use`
                      }`}
                    >
                      <FormattedMessage
                        id="app.footer.terms"
                        defaultMessage="Termos de utilização"
                      />
                    </Link>
                  </div>
                  <div className="col-auto">
                    <Link
                      fontSize="11px"
                      fontWeight="300"
                      color={theme.components.footer.linkColor}
                      marginBottom="10px"
                      display="block"
                      aria-label="Acessibilidade"
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/acessibilidade`
                          : `/${lang()}/accessibility`
                      }`}
                    >
                      <FormattedMessage
                        id="app.footer.accessibility"
                        defaultMessage="Acessibilidade"
                      />
                    </Link>
                  </div>
                  <div className="col-auto">
                    <Link
                      fontSize="11px"
                      fontWeight="300"
                      color={theme.components.footer.linkColor}
                      marginBottom="10px"
                      mdMarginBottom="0"
                      display="block"
                      aria-label="Mapa do site"
                      href={`${
                        lang() === "pt"
                          ? `/${lang()}/mapa-site`
                          : `/${lang()}/site-map`
                      }`}
                    >
                      <FormattedMessage
                        id="app.footer.map"
                        defaultMessage="Site map"
                      />
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Text
                      text={`Universidade de Aveiro ${moment().format("YYYY")}`}
                      fontSize="11px"
                      fontWeight="400"
                      color={theme.components.footer.copyColor}
                      className="copy-margin"
                    />
                  </div>
                </div>
              </ContentContainer>
            </div>
            <div className="col-xl-auto col-lg-12">
              <div className="row justify-content-lg-between align-items-end">
                <div className="col-auto">
                  <div className="logo">
                    <Link
                      fontDecoration="none"
                      href="https://www.ua.pt/stic/page/22825"
                      native
                    >
                      <Image
                        height="30px"
                        src={theme.components.footer.logos.compete}
                        alt="Logótipo Compete 2020"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="logo">
                    <AccessibilityLogo />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="logo">
                    <Link
                      fontDecoration="none"
                      href="http://www.eciu.org/"
                      target="_blank"
                      native
                    >
                      <Image
                        height="30px"
                        src="/imgs/logos/eciu_01.svg"
                        alt="Logótipo do Consórcio Europeu de Universidades Inovadoras"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="logo">
                    <Link
                      fontDecoration="none"
                      href="https://www.ua.pt/file/42005"
                      target="_blank"
                      native
                    >
                      <Image
                        height="30px"
                        src={theme.components.footer.logos.eche}
                        alt="Logótipo do ERASMUS charter for higher education 2014-2020"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="logo">
                    <Link
                      fontDecoration="none"
                      href="https://www.ua.pt/file/33409"
                      target="_blank"
                      native
                    >
                      <Image
                        height="30px"
                        src={theme.components.footer.logos.eps}
                        alt="Logótipo do ERASMUS policy statement"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="logo">
                    <Link
                      fontDecoration="none"
                      href="http://www.acessibilidade.gov.pt/simbolo"
                      target="_blank"
                      native
                    >
                      <Image
                        height="15px"
                        src={theme.components.footer.logos.accessibility}
                        alt="Símbolo de Acessibilidade à Web"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="logo">
                    <Link
                      fontDecoration="none"
                      href="https://www.cgd.pt/"
                      target="_blank"
                      native
                    >
                      <Image
                        height="25px"
                        src="/imgs/logos/logo_cgd.jpg"
                        alt="CGD"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section
        backgroundColor={theme.components.footer.backgroundColor}
        paddingTop="20px"
        paddingBottom="20px"
        lgPaddingBottom="40px"
        lgBorderTop="none"
        lgPaddingTop="20px"
        mdPaddingTop="0"
        hasHighContrastBorderTop={false}
      >
        <div className="container">
          <div className="row justify-content-lg-end no-gutters">
            <div className="col-sm-8 col-md-5 col-lg-6 col-xl-4">
              <div className="logo">
                <Link fontDecoration="none" href="https://www.ua.pt/" native>
                  <CustomImageMargins
                    height="20px"
                    src="/imgs/barra-financiamento-centro-norte-2020.svg"
                    alt="Logótipo Norte e Centro 2020"
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-8 col-md-5 col-lg-6 col-xl-3">
              <div className="logo">
                <Link fontDecoration="none" href="https://www.ua.pt/" native>
                  <CustomImageMarginPRR
                    height="25px"
                    src="/imgs/barra-financiamento-centro-norte.svg"
                    alt="Logótipo Compete 2030"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section
        backgroundColor={theme.components.footer.backgroundColor}
        paddingTop="20px"
        paddingBottom="20px"
        lgPaddingBottom="40px"
        lgBorderTop="none"
        lgPaddingTop="20px"
        mdPaddingTop="0"
        hasHighContrastBorderTop={false}
      >
        <div className="container">
          <div className="row justify-content-lg-end no-gutters">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7">
              <div className="logo">
                <Link fontDecoration="none" href="https://www.ua.pt/" native>
                  <CustomImageMargins
                    height="25px"
                    marginLeft="-10px"
                    src="/imgs/barra-financiamento-prr.svg"
                    alt="Logótipo Compete 2030"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  social: PropTypes.object,
  size: PropTypes.object
};

const FooterWithStyles = styled(Footer)`
  .sgs-logo {
    text-align: left;
    img {
      height: 100px;
      margin-top: 30px;
    }
  }
  @media (max-width: 1200px) {
    .sgs-logo {
      img {
        margin-top: -20px;
        height: 60px;
      }
    }
    .footer-sgs {
      padding-left: 0;
    }
  }

  @media (max-width: 991px) {
    .logo {
      margin-top: 10px;
    }
  }
  @media (max-width: 416px) {
    .copy-margin {
      margin-top: 10px;
    }
  }
`;

const CustomImageMargins = styled(Image)`
  margin-left: ${({ marginLeft }) => marginLeft || "-2px"};
`;

const CustomImageMarginPRR = styled(Image)`
  @media (max-width: 576px) {
    margin-left: -12px;
  }

  @media (min-width: 577px) and (max-width: 767px) {
    margin-left: -36px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin-left: 18px;
  }

  margin-left: -65px;
`;

export default compose(withSize(), withTheme)(FooterWithStyles);
