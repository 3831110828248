import React, { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { FAQ } from "@uaveiro/ui";

const FAQTEmplate = ({ match, ...props }) => {

  const [state, setState] = useState({
    isLoading: false,
    hasError: false,
    hasMultiple: false,
    activeColor: '#000',
    data: [],
    multipleData: []
  });

  const {
    params: { lang }
  } = match;

  const getData = async () => {
    try {

      setState(prevState => {
        return {
          ...prevState,
          title: props.title,
          isLoading: false,
          data: props.data,
          activeColor: props.activeColor,
          multipleData: props.multipleData,
          hasMultiple: props.hasMultiple,
          lang
        };
      });
    } catch (error) {
      setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          hasError: true,
        };
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);


  const {
    isLoading,
    hasError,
    data,
    multipleData,
    hasMultiple,
    title,
    activeColor
  } = state;

  const FAQProps = {
    isLoading,
    hasError,
    data,
    multipleData,
    hasMultiple,
    title,
    activeColor,
    lang
  };
  return <FAQ {...FAQProps} />;
};

export default withRouter(FAQTEmplate);
