import React from "react";
import PropTypes from "prop-types";
import { AnimatedBackground, ContentContainer } from "@uaveiro/ui";
import { withTheme } from "styled-components";

const Mask = props => {
  return (
    <ContentContainer
      backgroundColor={props.backgroundColor || props.theme.loadings.bgColor}
      position="absolute"
      {...props}
    />
  );
};

const LoadingCard = props => {
  return (
    <ContentContainer height="90px">
      <AnimatedBackground height="90px">
        <Mask {...props} height="25px" right="0" width="70%" />
        <Mask {...props} height="10px" top="25px" width="100%" />
        <Mask {...props} height="5px" right="0" top="35px" width="90%" />
        <Mask {...props} height="10px" top="40px" width="100%" />
        <Mask {...props} height="15px" right="0" top="50px" width="10%" />
        <Mask {...props} height="10px" top="65px" width="100%" />
        <Mask {...props} height="15px" right="0" top="75px" width="15%" />
      </AnimatedBackground>
    </ContentContainer>
  );
};

const Loading = ({ className, ...props }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-7 col-8">
            <LoadingCard {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default withTheme(Loading);
