import React, { memo, Fragment } from "react";
import PropTypes from "prop-types";
//Components
import Loading from "./Loading";
import { Section, ContentContainer, Text, Line, Button, Link } from "@uaveiro/ui";
import { ImageCard, InjectHtml, Error } from "@uaveiro/ui";
import { FormattedMessage } from "react-intl";
import { ImageOds } from "../../atoms";

//Utils
import { truncateOnWord } from "../../../services/utilities";
import { withTheme } from "styled-components";
import data from "../CourseSDG/data";

const Presentation = memo(function Presentation({
  className,
  hasSubTitle,
  hasLine,
  showMore,
  showImage,
  showButton,
  lang,
  openMore,
  onOpenMore,
  action,
  loading,
  content,
  hasError,
  learnMore,
  theme
}) {
  const objectives = data[lang].objectives;
  return (
    <Section
      className={className}
      backgroundColor={theme.components.presentation.backgroundColor}
      hasHighContrastBorderTop={false}
    >
      <div className="container">
        {loading ? (
          <Loading />
        ) : hasError ? (
          <Error />
        ) : (
          <Fragment>
            <div className="row no-gutters">
              <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0">
                <ContentContainer marginBottom="40px">
                  {hasSubTitle && (
                    <Text
                      as="h3"
                      text={content.data.subTitle}
                      fontWeight="300"
                      marginBottom="5px"
                      size="large"
                      firstLetter="capitalize"
                    />
                  )}
                  <Text
                    as="h1"
                    text={content.data.title}
                    marginBottom="10px"
                    size="xLarge"
                    fontWeight="500"
                    firstLetter="capitalize"
                  />
                  {hasLine && (
                    <Line color={theme.components.presentation.lineColor} />
                  )}
                </ContentContainer>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0">
                {content.data.description !== null && (
                  <InjectHtml
                    content={truncateOnWord(
                      content.data.description,
                      1500,
                      openMore,
                      content.data.description.length >= 1500 ? "..." : ""
                    )}
                    variant="presentation"
                  />
                )}
                {showMore &&
                  content.data.description !== null &&
                  content.data.description.length >= 1500 && (
                    <ContentContainer marginTop="15px">
                      <Link
                        text={`${openMore ? "ver mais" : "ver menos"}`}
                        aria-label={`${openMore ? "ver mais" : "ver menos"}`}
                        textDecoration="underline"
                        fontSize="14px"
                        color={theme.components.presentation.linkColor}
                        fontWeight="300"
                        hoverColor={
                          theme.components.presentation.linkHoverColor
                        }
                        onClick={onOpenMore}
                        default
                      />
                    </ContentContainer>
                  )}
              </div>
              <div className="col-xl offset-xl-0 col-lg-8 offset-lg-4 col-md-12 col-sm-12 col-12">
                <ContentContainer
                  marginLeft="30px"
                  mobileLgMarginLeft="0"
                  lgMargintop="40px"
                  borderTop={theme.components.presentation.card.border}
                  borderBottom={theme.components.presentation.card.border}
                  paddingTop="10px"
                >
                  <ImageCard
                    alt={content.data.image.title}
                    title={content.data.image.title}
                    name={content.data.image.name}
                    email={content.data.image.email}
                    hasPhoto={showImage}
                    url={
                      content.data.image.id != null
                        ? `/${lang}/p/${content.data.image.id}`
                        : null
                    }
                    src=""
                  />

                  <ContentContainer
                    mobileLgMarginLeft="0"
                    marginTop="10px"
                    paddingTop="10px"
                    borderTop={`1px solid ${theme.components.presentation.card.thinBorderColor}`}
                  >
                    <Text
                      fontWeight="500"
                      marginBottom="10px"
                      fontSize="14px"
                      lineHeight="1"
                    >
                      <FormattedMessage
                        id="app.course.shortInfo.departamentsAndSchools"
                        defaultMessage="Departamentos/Escolas politécnicas"
                      />
                    </Text>
                    {content.data.list.map(item => {
                      return (
                        <Link
                          key={item.id}
                          text={item.name.value}
                          title={item.name.value}
                          href={item.url.value}
                          target="_blank"
                          color={theme.components.presentation.linkColor}
                          fontWeight="300"
                          fontSize="14px"
                          textDecoration="underline"
                          hoverColor={
                            theme.components.presentation.linkHoverColor
                          }
                          display="block"
                          lineHeight="1.4"
                          marginBottom="10px"
                          default
                          native
                        />
                      );
                    })}
                  </ContentContainer>
                </ContentContainer>
                {showButton && (
                  <ContentContainer
                    marginTop="40px"
                    marginLeft="30px"
                    mobileLgMarginLeft="0"
                  >
                    <Button
                      width="100%"
                      onClick={() => action(content.data.payload.id)}
                      variant="default"
                    >
                      {content.data.payload.coursePlanLabel}
                    </Button>
                    {learnMore.show && (
                      <ContentContainer marginTop="30px">
                        <Button
                          width="100%"
                          variant="default"
                          onClick={learnMore.action}
                        >
                          {learnMore.text}
                        </Button>
                      </ContentContainer>
                    )}
                  </ContentContainer>
                )}
                
                { (content.data.ods !== undefined) && (content.data.ods !== null)  && 
                (content.data.ods.length > 0) && (content.data.ods.constructor === Array) && (
                  <div>
                    <ContentContainer  
                      className="flex-wrap"
                      display="flex"
                      justifyContent="space-between"
                      marginTop="30px"
                      marginLeft="30px"
                      mobileLgMarginLeft="0">
                          {
                            content.data.ods
                              .slice(0, 5).map( ods => (
                                  <ImageOds 
                                    language={lang}
                                    type="presentation"
                                    id={ods}  
                                    key={ods}
                                    title= {objectives[ods-1].label}
                                    link="#ods"
                                  />
                            ))
                          }
                          <ImageOds 
                            language={lang}
                            type="presentation"
                            id={0}  
                            link="#ods"
                          />
                    </ContentContainer>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Section>
  );
});

const defaults = {
  hasSubTitle: false,
  hasLine: false,
  showMore: false,
  showImage: false,
  hasEndpoint: true,
  learnMore: {
    action: () => null,
    text: "Mais Informações",
    show: true
  }
};

const types = {
  className: PropTypes.string,
  hasSubTitle: PropTypes.bool,
  hasLine: PropTypes.bool,
  showMore: PropTypes.bool,
  history: PropTypes.object,
  showImage: PropTypes.bool,
  showButton: PropTypes.bool,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string,
  openMore: PropTypes.bool,
  onOpenMore: PropTypes.func,
  action: PropTypes.func,
  loading: PropTypes.bool,
  content: PropTypes.object,
  hasError: PropTypes.bool,
  theme: PropTypes.object,
  learnMore: PropTypes.object
};

Presentation.propTypes = types;
Presentation.defaultProps = defaults;

export default withTheme(Presentation);
