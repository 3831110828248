import React, { useEffect, useState, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//components
import { SubWebPeople } from "@uaveiro/ui";
//API
import {
  getDepartmentPeople,
  getProfilesPhotos,
  getDepartmentManagement
} from "../../api/requests";
//utils
import { portalAPI } from "../../services/constants";
import { uuidv4, getAuthUserInformation } from "../../services/utilities";
//Context
import { StoreContext } from "../../context/StoreContext";
//Hooks
import { useQueryBuilder } from "../../hooks";
import styled from "styled-components";

const localeTitle = {
  pt: {
    people: "Pessoas",
    management: "Gestão"
  },
  en: {
    people: "People",
    management: "Management"
  }
};

const SubWebPeopleTemplate = ({ match, group, ...props }) => {
  const context = useContext(StoreContext);
  const [state, setState] = useState({
    isLoading: true,
    isTabsLoading: true,
    hasError: false,
    data: [],
    selected: 0,
    tabs: [],
    popcorn: true
  });

  const { lang } = match.params;

  const isManagementPeople = useMemo(() => group === "management", [group]);

  const title = useMemo(
    () =>
      isManagementPeople
        ? localeTitle[lang].management
        : localeTitle[lang].people,
    [isManagementPeople, lang]
  );

  const { id: departmentId } = context.state.subWebNav;
  const auth = getAuthUserInformation();

  const [urlParams, setUrlParams, searchParams] = useQueryBuilder(
    match.url,
    props.history.location.search
  );

  const onFetchAllPhotos = async content => {
    try {
      const filterIds = content.data
        .map(section =>
          section.list
            .filter(res => res.private)
            .map(person => {
              return person.id;
            })
            .join("&id=")
        )
        .filter(res => res.length > 0)
        .join("&id=");

      const multiIds = `id=${filterIds}`;

      const profilePhotos = await getProfilesPhotos({
        ids: multiIds,
        lang
      });

      const newFilterData = content.data.map(section => {
        let children = section.list.map(person => {
          let [profile] = profilePhotos.filter(item => item.id === person.id);

          let image =
            profile !== undefined
              ? `data:image/png;base64,${profile.image}`
              : `${portalAPI}/person/${person.id}/photo`;
          return { image, ...person, uuid: uuidv4() };
        });

        return {
          list: [...children],
          title: section.title
        };
      });

      setState(prevState => {
        return {
          ...prevState,
          data: newFilterData,
          popcorn: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          isTabsLoading: false,
          popcorn: true
        };
      });
    }
  };

  const onFetchData = async (department = "deca", type = "") => {
    try {
      const requestType = isManagementPeople
        ? getDepartmentManagement
        : getDepartmentPeople;

      const res = await requestType({
        department,
        type,
        lang
      });

      return res;
    } catch (error) {
      setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          isTabsLoading: false,
          hasError: true
        };
      });
    }
  };

  const onShowError = result => {
    if (result === undefined) {
      setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          isTabsLoading: false,
          hasError: true
        };
      });

      return true;
    }
  };

  const onFetchContent = async (department, type) => {
    const result = await onFetchData(department, type);

    if (onShowError(result)) return;

    setState(prevState => {
      return {
        ...prevState,
        data: result.data,
        selected: Number(result.selected),
        isLoading: false
      };
    });

    const hasPrivates = result.data
      .map(section => section.list.filter(res => res.private))
      .filter(res => res.length > 0);

    if (auth.authenticated && hasPrivates.length > 0) {
      //Gets and loads the profiles photos
      onFetchAllPhotos(result);
    }
  };

  const onFetchTabs = async (departmentId, type) => {
    const result = await onFetchData(departmentId);

    if (onShowError(result)) return;

    setState(prevState => {
      return {
        ...prevState,
        tabs: result.tabs,
        selected: type ? Number(type) : Number(result.selected),
        isTabsLoading: false
      };
    });
  };

  useEffect(() => {
    const type = searchParams.staff !== undefined ? searchParams.staff : "";

    onFetchTabs(departmentId, type);
    onFetchContent(departmentId, type);
  }, [match, departmentId]);

  const onChangeTab = async value => {
    setState(prevState => {
      return {
        ...prevState,
        isLoading: true
      };
    });
    setUrlParams({
      ...urlParams,
      staff: value
    });
    onFetchContent(departmentId, value);
  };

  const peopleProps = {
    ...state,
    title,
    activeColor: props.activeColor ?? context.state.activeColor,
    onChangeTab,
    apiUrl: portalAPI,
    lang,
    ...props
  };

  return (
    <SubWebPeopleStyleOverride
      {...(peopleProps.structure === true ? { ...peopleProps } : { ...state })}
      activeColor={peopleProps.activeColor}
      onChangeTab={onChangeTab}
      popcorn={state.popcorn}
      isEditor={false}
    />
  );
};

SubWebPeopleTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  group: PropTypes.string.isRequired
};

export default withRouter(SubWebPeopleTemplate);

// this could be done on the ui library instead of an override
const SubWebPeopleStyleOverride = styled(SubWebPeople)`
  i {
    @media (max-width: 575px) {
      font-size: 80px;
    }
  }
`;
