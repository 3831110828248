import React, { memo, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import Presentation from "../../components/organisms/Presentation";

import SEO from "../SEO";
//Utils
import { compose } from "../../services/utilities";
//API
import { getCoursePresentationById } from "../../api/requests";
//Utils
import { isEmpty } from "../../services/utilities";

const PresentationTemplate = memo(function PresentationTemplate({
  history,
  match,
  ...props
}) {
  const [openMore, setOpenMore] = useState(true);
  const [state, setState] = useState({
    loading: true,
    error: false,
    content: {},
    showLearnMore: false,
    payload: {},
    showButton: true
  });

  const goToPlan = id => {
    history.push(`/${match.params.lang}/c/${id}/p`);
  };

  const goToLearnMore = () => {
    const { lang, id } = match.params;
    if (state.payload.portalPage === "internal") {
      history.push(`/${lang}/c/${id}/info`);
    } else {
      if (typeof window !== "undefined") {
        window.open(state.payload.portalPageLink, "_blank");
      }
    }
  };

  const onOpenMore = e => {
    e.preventDefault();
    setOpenMore(!openMore);
  };

  const onFetchContent = async () => {
    try {
      const { lang, id } = match.params;
      const data = await getCoursePresentationById({
        id,
        lang,
        hasDelay: true,
        history
      });

      const {
        data: { payload }
      } = data;
      
      setState(prevState => {
        return {
          ...prevState,
          content: { ...data },
          loading: false,
          showLearnMore: !isEmpty(payload.portalPage),
          payload,
          showButton:
            data.data.payload.showCoursePlanLink == undefined ||
            data.data.payload.showCoursePlanLink
        };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  //If the user changes the url update the content
  useEffect(() => {
    onFetchContent();
  }, [history.location]);

  const presentationProps = {
    openMore,
    onOpenMore,
    action: goToPlan,
    history,
    match,
    learnMore: {
      action: goToLearnMore,
      text:
        match.params.lang === "pt" ? "Mais Informações" : "More Information",
      show: state.showLearnMore
    },
    showButton: state.showButton,
    ...props
  };

  return (
    <Fragment>
      {!state.loading && !state.error && (
        <SEO
          title={state.content.data.title}
          description={state.content.data.description}
        />
      )}

      <Presentation
        content={state.content}
        loading={state.loading}
        hasError={state.error}
        lang={match.params.lang}
        {...presentationProps}
      />
    </Fragment>
  );
});

PresentationTemplate.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object.isRequired
};

export default compose(withRouter)(PresentationTemplate);
