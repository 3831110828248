/* eslint-disable no-script-url*/
import React, { Fragment } from "react";
import PropTypes from "prop-types";
//Styles
import styled, { withTheme } from "styled-components";
//containers
import AccessibilitySelector from "../../../containers/AccessibilitySelector/index";
//components
import { InstituteSearch } from "../../molecules";
import { Link } from "../../atoms";
import { SideNav, SmartContainer } from "../../organisms";
import {
  HorizontalList,
  ListItem,
  Icon,
  Image,
  Title,
  ScreenReaderText,
  Dropdown,
  DropdownList
} from "@uaveiro/ui";
import { ContentContainer } from "../../molecules";

//Locales
import { FormattedMessage } from "react-intl";
import JournalNavigation from "../../../templates/Journal/JournalNavigation";
import { rem } from "polished";
import { SubWebNavigation } from "../../../templates";

const WebNavbar = ({
  className,
  isSearchOpen,
  searchToggle,
  institute,
  history,
  height,
  isFirefox,
  isDropdownOpen,
  setDropdownOpen,
  isFocus,
  onChangeZoom,
  onChangeAccessibilityFilter,
  onChangeLang,
  windowSize,
  onHandleClear,
  onHandleSearchType,
  onHandleSearch,
  hasHash,
  lang,
  activeView,
  accessibilityValue,
  zoomValue,
  setSearchValue,
  searchType,
  journalNavigationHeight,
  journalNavigationShadow,
  theme,
  hasInstituteLogo,
  hasEmbeddedSearch,
  subWebNavigationShadow,
  hideSubWebLogoDescription,
  tabs,
  ...props
}) => {
  return (
    <nav className={className}>
      <ScreenReaderText>
        <Title text="Navegação Principal" />
      </ScreenReaderText>
      <div className="web-nav-bar">
        <div className="container">
          <div className="row no-gutters align-items-center container-row">
            <div className="col-xl-3 col-lg-auto">
              {hasInstituteLogo ? (
                <div
                  className="institute-logo"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <Link
                    href={`${hasHash}/`}
                    aria-label="Logótipo da Universidade de Aveiro, ir para a página inicial"
                    display="inline-block"
                  >
                    {isSearchOpen ? (
                      <Image
                        src={theme.components.webNavBar.mobileBlackLogo}
                        objectFit="unset"
                      />
                    ) : (
                      <Image
                        src={theme.components.webNavBar.mobileWhiteLogo}
                        objectFit="unset"
                        alt=" "
                      />
                    )}
                  </Link>
                  {/*<Link
                    href={`${hasHash}/`}
                    aria-label="Logótipo da Universidade de Aveiro, ir para a página inicial"
                    display="inline-block"
                  >
                    {isSearchOpen ? (
                      <Fragment>
                        {windowSize <= 1199 || height === 80 ? (
                          <Image
                            src={theme.components.webNavBar.mobileBlackLogo}
                            objectFit="unset"
                          />
                        ) : (
                          <Image
                            src={theme.components.webNavBar.blackLogo}
                            objectFit="unset"
                          />
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {windowSize <= 1199 || height === 80 ? (
                          <Image
                            src={theme.components.webNavBar.mobileWhiteLogo}
                            objectFit="unset"
                            alt=" "
                          />
                        ) : (
                          <Image
                            src={theme.components.webNavBar.whiteLogo}
                            objectFit="unset"
                            alt=" "
                          />
                        )}
                      </Fragment>
                    )}
                  </Link>*/}
                </div>
              ) : (
                <ContentContainer marginRight="30px">
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    href="/"
                    fontSize={rem("16px")}
                    display="inline-block"
                    color={theme.components.webNavBar.linksColor}
                    fontDecoration="none"
                  >
                    Universidade de Aveiro
                  </Link>
                </ContentContainer>
              )}
            </div>

            <div className="col institute-links-container">
              <div className="institute-links">
                <HorizontalList display="inline-block">
                  {/* institute.links */ tabs.map((item, index) => {
                    return (
                      <ListItem key={index} marginRight="30px">
                        <Dropdown
                          onMouseEnter={() => setDropdownOpen(true)}
                          onMouseLeave={() => setDropdownOpen(false)}
                          paddingBottom="20px"
                          marginTop="15px"
                          withUnderlineAnimation
                        >
                          <Link
                            key={history.location.pathname}
                            cursor="pointer"
                            tabIndex={0}
                            href={`/${lang}/${item.url}`}
                            aria-label={item.title}
                            onFocus={() => setDropdownOpen(true)}
                            onClick={() => setDropdownOpen(false)}
                            onMouseLeave={e => e.currentTarget.blur()}
                            {...item.styles}
                            fontSize={rem("14px")}
                            color={theme.components.webNavBar.linksColor}
                          >
                            {item.title}
                          </Link>

                          {props.pathname !== "/" &&
                            activeView === item.activeView && (
                              <ContentContainer
                                className="active-line"
                                position="absolute"
                                width="30px"
                                height="3px"
                                marginTop="5px"
                                backgroundColor={
                                  theme.highContrast.link ||
                                  item.activeColor ||
                                  theme.components.webNavBar.activeLineColor
                                }
                              >
                                &nbsp;
                              </ContentContainer>
                            )}

                          {item.enableDropdown && isDropdownOpen && (
                            <DropdownList fontWeight="400">
                              {item.links.map((item, index) => {
                                return (
                                  <Link
                                    tabIndex={0}
                                    key={index}
                                    href={`/${lang}/${item.url}`}
                                    aria-label={item.title}
                                    onClick={() => setDropdownOpen(false)}
                                    {...item.styles}
                                  >
                                    {item.text}
                                  </Link>
                                );
                              })}
                            </DropdownList>
                          )}
                        </Dropdown>
                      </ListItem>
                    );
                  })}
                </HorizontalList>
              </div>
            </div>

            {isSearchOpen ? (
              <div className="col">
                <InstituteSearch
                  type="default"
                  isFocus={isFocus}
                  removeLeftIcon
                  onKeyPress={onHandleSearch}
                  onChange={e => setSearchValue(e.target.value)}
                  showClear
                  clear={onHandleClear}
                />
                <div className="search-filters">
                  <HorizontalList display="inline-block">
                    <ListItem>
                      <Link
                        onClick={e => onHandleSearchType(e, "a")}
                        fontWeight={searchType === "a" ? "400" : "300"}
                        color={theme.components.webNavBar.searchFilterColor}
                      >
                        <FormattedMessage
                          id="app.search.all.title"
                          defaultMessage="Todo o Portal"
                        />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        onClick={e => onHandleSearchType(e, "p")}
                        fontWeight={searchType === "p" ? "400" : "300"}
                        color={theme.components.webNavBar.searchFilterColor}
                      >
                        <FormattedMessage
                          id="app.search.people.title"
                          defaultMessage="Pessoas"
                        />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        onClick={e => onHandleSearchType(e, "n")}
                        fontWeight={searchType === "n" ? "400" : "300"}
                        color={theme.components.webNavBar.searchFilterColor}
                      >
                        <FormattedMessage
                          id="app.search.news.title"
                          defaultMessage="Notícias"
                          color="red"
                        />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        onClick={e => onHandleSearchType(e, "l")}
                        fontWeight={searchType === "l" ? "400" : "300"}
                        color={theme.components.webNavBar.searchFilterColor}
                      >
                        <FormattedMessage
                          id="app.search.locals.title"
                          defaultMessage="Locais"
                        />
                      </Link>
                    </ListItem>
                  </HorizontalList>
                </div>
              </div>
            ) : null}

            <div className="col-auto icons-container">
              <HorizontalList display="inline-block">
                <AccessibilitySelector
                  accessibilityValue={accessibilityValue}
                  isDropdownOpen={isDropdownOpen}
                  setDropdownOpen={setDropdownOpen}
                  isFirefox={isFirefox}
                  onChangeAccessibilityFilter={onChangeAccessibilityFilter}
                  onChangeLang={onChangeLang}
                  onChangeZoom={onChangeZoom}
                  zoomValue={zoomValue}
                  lang={lang}
                  theme={theme}
                  hasEmbeddedSearch={hasEmbeddedSearch}
                  searchToggle={searchToggle}
                />
              </HorizontalList>
            </div>
          </div>
        </div>
      </div>

      {props.GlobalStore.state.showSideNav && (
        <SmartContainer>
          <SideNav scrollPostion={props.movedPosition} />
        </SmartContainer>
      )}

      {props.GlobalStore.state.isNews && (
        <JournalNavigation
          title="Notícias"
          resource="app.journal.nav.title"
          journalNavigationHeight={journalNavigationHeight + "px"}
          journalNavigationShadow={journalNavigationShadow}
        />
      )}

      {props.GlobalStore.state.isSubWeb && (
        <SubWebNavigation
          hideSubWebLogoDescription={hideSubWebLogoDescription}
          subWebNavigationShadow={subWebNavigationShadow}
        />
      )}
    </nav>
  );
};

WebNavbar.defaultProps = {
  hasInstituteLogo: true,
  hasEmbeddedSearch: true,
  lang: "pt"
};

WebNavbar.propTypes = {
  className: PropTypes.string,
  isSearchOpen: PropTypes.bool,
  searchToggle: PropTypes.func,
  pathname: PropTypes.string,
  institute: PropTypes.object,
  history: PropTypes.object.isRequired,
  height: PropTypes.number,
  journalNavigationHeight: PropTypes.number,
  isFirefox: PropTypes.bool,
  isDropdownOpen: PropTypes.bool,
  setDropdownOpen: PropTypes.func.isRequired,
  isFocus: PropTypes.bool,
  onChangeZoom: PropTypes.func.isRequired,
  onChangeAccessibilityFilter: PropTypes.func.isRequired,
  onChangeLang: PropTypes.func.isRequired,
  windowSize: PropTypes.number.isRequired,
  onHandleClear: PropTypes.func.isRequired,
  onHandleSearchType: PropTypes.func.isRequired,
  onHandleSearch: PropTypes.func.isRequired,
  hasHash: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  activeView: PropTypes.string.isRequired,
  accessibilityValue: PropTypes.number.isRequired,
  zoomValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  movedPosition: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  searchType: PropTypes.string,
  journalNavigationShadow: PropTypes.bool,
  GlobalStore: PropTypes.object
};

const WebNavbarWithStyles = styled(WebNavbar)`
  z-index: 100;
  height: auto;
  width: 100%;
  position: ${props => (props.isIE() ? "relative" : "sticky")};
  top: 0;

  .web-nav-bar {
    //highContrast only border
    border-top: ${props => props.theme.highContrast.border};
    border-bottom: ${props => props.theme.highContrast.border};

    background-color: ${props =>
      props.theme.components.webNavBar.backgroundColor};

    ${props =>
      props.isSearchOpen
        ? `
      background: ${props.theme.components.webNavBar.isSearchOpenBackgroundColor};
      .institute-links-container, .icons-container {
        display: none;
      }`
        : null}
  }

  //Filters
  .search-filters {
    a {
      font-size: 14px;
    }
    li {
      margin: 10px 15px 0 15px;
      :first-child {
        margin-left: 0;
      }
    }
  }

  .container-row {
    transition: height 0.2s ease;
    -webkit-transition: height 0.2s ease;
    height: ${props => props.height}px;
  }

  .institute-logo {
    img {
      ${props => (props.height === 80 ? `height: 44px;` : `height: 52px;`)}
    }
  }

  .icons-container {
    text-align: right;
  }

  .institute-links {
    li {
      :last-child {
        margin-right: 0;
      }
    }
  }

  ${props =>
    props.hasLinksBottomAdjustments &&
    `
    .institute-links-container,
    .icons-container {
      position: relative;
      bottom: 4px;
    }
  `}

  //Acessibility icon dropdown
  .accessibility {
    position: relative;

    :first-child {
      margin-right: 0;
    }
    .acessibility-icon-dropdown {
      display: inline-block;
      padding: 10px 8px 11px 10px;
      a {
        padding: 4px;
      }
    }

    .dropdown-content {
      .acessibility-icon {
        padding: 8px 0;
        text-align: center;

        :hover {
          background-color: ${props =>
            props.theme.components.webNavBar.dropDownHoverBackgroundColor};
          cursor: pointer;
          i {
            color: ${props =>
              props.theme.components.webNavBar.accessibilityIconHoverColor};
          }
        }
      }
      .acessibility-icon.active {
        background-color: ${props =>
          props.theme.components.webNavBar.accessibilityActiveBgColor};
        i {
          color: ${props =>
            props.theme.components.webNavBar.accessibilityIconActiveColor};
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .institute-logo {
      margin-right: 30px;
      img {
        width: 125px;
      }
    }
  }
`;

WebNavbarWithStyles.defaultProps = {
  name: "fa-info",
  cursor: "default",
  height: 105,
  hasLinksBottomAdjustments: true
};

export default withTheme(WebNavbarWithStyles);
