import React, { useMemo, useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SvgWrapper = styled.a`
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  border-radius: ${props => props.borderRadius};
  object-fit: ${props => props.objectFit};
  display: inline-block;
  cursor: pointer;
  width: 134px;
  height: 134px;

  svg {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 768px) {
    width: 104px;
    height: 104px;
  }
  @media (min-width: 992px) {
    width: 90px;
    height: 90px;
  }
  @media (min-width: 1200px) {
    width: 75px;
    height: 75px;
  }
`;

// Custom hook to manage hover state
const useHover = (ref) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = useCallback(() => setHovered(true), []);
  const handleMouseLeave = useCallback(() => setHovered(false), []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (ref.current) {
        const isInside = ref.current.contains(e.target);
        if (isInside && !hovered) {
          setHovered(true);
        } else if (!isInside && hovered) {
          setHovered(false);
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [hovered, ref]);

  return { hovered, handleMouseEnter, handleMouseLeave };
};

const ImageOds = ({ id, type, link, title, language, ...props }) => {
  const svgWrapperRef = useRef(null);
  const { hovered, handleMouseEnter, handleMouseLeave } = useHover(svgWrapperRef);

  const iconId = useMemo(() => {
    const prefix = type === "presentation" ? "bw" : "color";
    const lang = language === "pt" ? "pt" : "en";
    const name = id === 0 ? 'plus' : `ODS-${id}`;
    return `/icons/ods/${prefix}-${lang}.svg#${name}`;
  }, [id, type, language]);

  const hoverIconId = useMemo(() => {
    const lang = language === "pt" ? "pt" : "en";
    const name = id === 0 ? 'plus' : `ODS-${id}`;
    return type === "presentation"
      ? `/icons/ods/color-${lang}.svg#${name}`
      : `/icons/ods/plus.svg#${name}`;
  }, [id, type, language]);

  const svgContent = hovered ? hoverIconId : iconId;

  const handleHashClick = useCallback((e) => {
    if (link.includes("#")) {
      e.preventDefault();
      window.history.pushState(null, '', link);
      const elementId = link.substring(1); // Remove the leading '#'
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
   
  return (
    <SvgWrapper
      title={title}
      ref={svgWrapperRef}
      {...props}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      href={link} onClick={handleHashClick}
    >
        <svg>
          <use href={svgContent} />
        </svg>
    </SvgWrapper>
  );
};
const types = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string,
  alt: PropTypes.string,
  borderRadius: PropTypes.string,
  objectFit: PropTypes.string,
  minHeight: PropTypes.string,
  children: PropTypes.node
};

const defaults = {
  alt: "ODS",
  title: "",
  objectFit: "cover",
  borderRadius: "0",
};

ImageOds.propTypes = types;

ImageOds.defaultProps = defaults;

export default ImageOds;

//Only used for the Storybook Props Table
export const WithProps = ({ children, ...props }) => (
  <ImageOds {...props}>{children}</ImageOds>
);

WithProps.propTypes = types;

WithProps.defaultProps = defaults;