import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
//Components
import { Streaming } from "@uaveiro/ui";

const VideoTemplate = ({ match, ...props }) => {
  const {
    params: { lang }
  } = match;

  const videoProps = {
    ...props,
    lang,
    disableCookiesCheck: false, // we must force this prop value to false because it always comes from the editor with value true
  };

  return <Streaming {...videoProps} />;
};

VideoTemplate.propTypes = {
  match: PropTypes.object
};

export default withRouter(VideoTemplate);
