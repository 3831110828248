const data = [
  {
    name: "Article",
    hasSubTitle: false,
    hasLine: false,
    hasSEO: false,
    activeColor: "#92D400",
    data: {
      pt: {
        title: "Política de Cookies",
        content:
          '<button id="ot-sdk-btn" class="ot-sdk-show-settings">Alterar definições de cookies (Portal)</button>\n\n<div id="ot-sdk-cookie-policy"></div>'
      },
      en: {
        title: "Cookies Policy",
        content:
          '<button id="ot-sdk-btn" class="ot-sdk-show-settings">Change cookies policy (Portal)</button>\n\n<div id="ot-sdk-cookie-policy"></div>'
      }
    }
  }
];

export default data;
